import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { isValidArray, tr } from 'mmfintech-commons'
import { PaymentOptionResponse, PaymentOptionStatusEnum } from 'mmfintech-commons-types'

import './PaymentMethods.scss'

import { useMatchMedia } from '@hooks'

import JetonIcon from '@images/methods/jeton-icon.svg?react'
import CashToCodeIcon from '@images/methods/cashtocode-icon.svg?react'
import BankTransferIcon from '@images/methods/bank-transfer-icon.svg?react'
import SwiftIcon from '@images/methods/swift-icon.svg?react'
import PerfectMoneyIcon from '@images/methods/perfect-money-icon.svg?react'
import CardIcon from '@images/methods/debit-card-icon.svg?react'
import KingdomCashIcon from '@images/methods/kingdom-cash-icon.svg?react'
import PixIcon from '@images/methods/pix-icon.svg?react'
import DefaultIcon from '@images/methods/paymen-method-icon.svg?react'
import KhipuIcon from '@images/methods/khipu-icon.svg?react'
import SafetyPayIcon from '@images/methods/safetypay-icon.svg?react'
import CryptoIcon from '@images/methods/crypto-icon.svg?react'
import InternalTransferIcon from '@images/methods/internal-transfer.svg?react'
import InteracIcon from '@images/methods/interac-icon.svg?react'
import BankwireIcon from '@images/methods/bankwire-icon.svg?react'
import BatchPaymentIcon from '@images/icons/batch-payment-icon.svg?react'

type TMorePaymentOptions = {
  description?: string
  title?: string
  dataTest?: string
  path?: string
  isDisabled?: boolean
}
interface IPaymentMethodProps {
  paymentOptions: (PaymentOptionResponse & TMorePaymentOptions)[]
  setSelectedPaymentOption?: (value: string, path?: string) => void
  selectedPaymentOption?: PaymentOptionResponse
  collapse?: boolean
  loading?: boolean
  disabled?: boolean
  setCollapse?: Dispatch<SetStateAction<boolean>>
  ignoreStatus?: boolean
}

export const PaymentMethods = ({
  paymentOptions,
  setSelectedPaymentOption,
  selectedPaymentOption,
  collapse,
  // loading,
  disabled = false,
  setCollapse,
  ignoreStatus = false
}: IPaymentMethodProps) => {
  const enableCollapse = useMatchMedia({ breakpoint: 1280, prefix: 'max' }) // collapse state (breakpoint)
  const { paymentMethod, feePercent } = selectedPaymentOption || {}
  const [filteredOptions, setFilteredOptions] =
    useState<(PaymentOptionResponse & TMorePaymentOptions)[]>(paymentOptions)
  const paymentMethodsContainer = useRef(null)

  useEffect(() => {
    if (enableCollapse && isValidArray(paymentOptions) && paymentMethod && selectedPaymentOption) {
      setTimeout(() => {
        setFilteredOptions(paymentOptions.filter(option => option.paymentMethod !== paymentMethod))
      }, 300)
    } else {
      setFilteredOptions(paymentOptions)
    }
  }, [enableCollapse, selectedPaymentOption, paymentOptions])

  useEffect(() => {
    if (enableCollapse && !collapse && paymentMethodsContainer.current) {
      paymentMethodsContainer.current.scroll(0, 0)
    }
  }, [collapse, enableCollapse])

  const PaymentOptionIcon = (paymentMethod: string): JSX.Element => {
    switch (paymentMethod) {
      case 'INSTANT_BANK_TRANSFER':
        return <BankTransferIcon />
      case 'JETON_CASH':
        return <JetonIcon />
      case 'CASH_TO_CODE':
        return <CashToCodeIcon />
      case 'BANKWIRE':
        return <BankwireIcon />
      case 'SWIFT_BANKWIRE':
        return <SwiftIcon />
      case 'PERFECT_MONEY':
        return <PerfectMoneyIcon />
      case 'CREDIT_CARD':
        return <CardIcon />
      case 'KINGDOM_CASH':
      case 'INTERNAL_TRANSFER':
        return <KingdomCashIcon />
      case 'PIX':
        return <PixIcon />
      case 'KHIPU':
        return <KhipuIcon />
      case 'SAFETYPAY_BANK':
      case 'SAFETYPAY_CASH':
        return <SafetyPayIcon />
      case 'CRYPTO':
        return <CryptoIcon />
      case 'INTRA_ACCOUNT_TRANSFER':
        return <InternalTransferIcon />
      case 'INTERAC':
        return <InteracIcon />
      case 'BATCH':
        return <BatchPaymentIcon />

      default:
        return <DefaultIcon />
    }
  }

  const hoverAttributes = () => {
    return {
      onMouseOver: event => {
        const target = event.target as HTMLSpanElement
        event.stopPropagation()
        if (target.clientWidth > target.parentElement.clientWidth) {
          target.style.transform = `translateX(${-(target.clientWidth - target.parentElement.clientWidth + 24)}px)`
        }
      },
      onMouseLeave: event => {
        const target = event.target as HTMLSpanElement
        target.style.transform = `translateX(0px)`
      }
    }
  }

  return (
    <div
      style={{
        height: enableCollapse && collapse ? `${8.8 * filteredOptions.length}rem` : '',
        overflow: enableCollapse && !collapse ? `hidden` : 'auto'
      }}
      className={cn('payment-methods-wrapper')}
      ref={paymentMethodsContainer}>
      {enableCollapse && selectedPaymentOption && (
        <div className={cn('payment-method-wrapper full-width selected')} onClick={() => setCollapse(true)}>
          <div className='payment-method-icon-wrapper'>{PaymentOptionIcon(paymentMethod)}</div>
          <div className='payment-method-content'>
            <div className='payment-method-title-wrapper'>
              <span {...hoverAttributes()}>{tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}</span>
            </div>
            {feePercent !== undefined && (
              <span className='subtitle'>{`${tr('FRONTEND.PAYMENT_OPTIONS.FEE_LABEL', 'Fee')} ${feePercent} %`}</span>
            )}
          </div>
        </div>
      )}
      {isValidArray(filteredOptions) &&
        filteredOptions.map(method => {
          const { paymentMethod, feePercent, description, title, dataTest, path, isDisabled, status } = method || {}
          return (
            <div
              key={paymentMethod}
              onClick={() => setSelectedPaymentOption(paymentMethod, path)}
              date-test={dataTest}
              className={cn('payment-method-wrapper', {
                'full-width': filteredOptions.length === 1,
                selected:
                  !enableCollapse && selectedPaymentOption && selectedPaymentOption.paymentMethod === paymentMethod,
                disabled: disabled || isDisabled || (!ignoreStatus && status !== PaymentOptionStatusEnum.AVAILABLE)
              })}>
              <div className='payment-method-icon-wrapper'>{PaymentOptionIcon(paymentMethod)}</div>
              <div className='payment-method-content'>
                <div className='payment-method-title-wrapper'>
                  <span {...hoverAttributes()}>
                    {title || tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                  </span>
                </div>

                {description && <span className='subtitle'>{description}</span>}

                {feePercent && (
                  <span className='subtitle'>{`${tr(
                    'FRONTEND.PAYMENT_OPTIONS.FEE_LABEL',
                    'Fee'
                  )} ${feePercent} %`}</span>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

import { formatFloat, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'
import { DataTable, ErrorDisplay } from 'mmfintech-portal-commons'
import { useMerchantAccounts } from 'mmfintech-backend-api'

import { useMatchMedia, useUserPermissions } from '@hooks'
import { CoreCurrencyIcon, CoreLoader } from '@components'
import { breakpoints } from '@constants'

import './accountsOverview.scss'

const { Table, Body, Cell, Head, HeadCell, Row } = DataTable

type AccountOverviewRowProps = {
  id: number
  currencyCode: string
  balance: number
  name: string
  permission?: string
}

export const AccountsOverview = () => {
  const { isLoading, permissions } = useUserPermissions()
  const { activeAccounts, accountsFetching, accountsError } = useMerchantAccounts()
  const isLarge = useMatchMedia({ breakpoint: breakpoints['lg'], prefix: 'min' })

  const accounts = activeAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <div className='account-overview-list-wrapper'>
      {/* TODO: localization */}
      <h2 className='account-overview-list-title'>{tr('TODO', 'Accounts')}</h2>

      <ErrorDisplay error={accountsError} />

      {accountsFetching || isLoading ? (
        <CoreLoader />
      ) : (
        <div className='table-container'>
          <Table>
            <Head>
              <Row className='headRow'>
                <HeadCell>
                  {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.CURRENCY', 'Currency')}
                </HeadCell>
                <HeadCell>
                  {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.ACCOUNT_NAME', 'Account Name')}
                </HeadCell>
                <HeadCell>
                  {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.PERMISSIONS', 'Permissions')}
                </HeadCell>
                {isLarge && (
                  <HeadCell>
                    {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.BALANCE', 'Balance')}
                  </HeadCell>
                )}
              </Row>
            </Head>
            {isValidArray(accounts) && (
              <Body>
                {accounts.map((account: AccountOverviewRowProps) => {
                  const { currencyCode, balance, name, permission, id } = account

                  return (
                    <Row key={id}>
                      <Cell className='accounts-cell'>
                        <div className='currency-box-container'>
                          <CoreCurrencyIcon currency={currencyCode} circle width='40px' height='40px' />
                          <div className='currency-box'>
                            <span className='currency'>{currencyCode}</span>

                            {!isLarge && (
                              <span>{formatFloat(balance || 0, getCurrencyDecimals(currencyCode || ''))}</span>
                            )}
                          </div>
                        </div>
                      </Cell>
                      <Cell> {name}</Cell>
                      <Cell>
                        {permission === 'WRITER'
                          ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.FULL.TITLE', 'Full')
                          : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.VIEW_ONLY', 'View Only')}
                      </Cell>
                      {isLarge && (
                        <Cell>
                          {formatFloat(balance || 0, getCurrencyDecimals(currencyCode || ''))} {currencyCode}
                        </Cell>
                      )}
                    </Row>
                  )
                })}
              </Body>
            )}
          </Table>
        </div>
      )}
    </div>
  )
}

import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import cn from 'classnames'
import toast from 'react-hot-toast'

import { useMatchMedia } from '@hooks'
import { paths, useCountries, useGetMerchantQuery, useIbanRequest } from 'mmfintech-backend-api'
import { formatFloat, getCurrencyDecimals, GlobalContext, isValidArray, isValidObject, tr } from 'mmfintech-commons'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { HeaderContext } from '../../../context/HeaderContext'
import { AccountCreateModal } from './elements/AccountCreateModal'
import { IbanRequestMethods } from './elements/ibanRequestMethods'
import { IbanRequestFeePayment } from './elements/IbanIssuingPreview'
import { IbanRequestSuccessPane } from './elements/IbanRequestSuccess'
import { CoreButton, CoreCurrencyIcon, CoreInput, CoreSelect } from '@components'

import {
  BankAccountIssuingPreviewRequest,
  BankAccountScheme,
  CountrySupportedPurposeEnum,
  MerchantEntityTypeEnum
} from 'mmfintech-commons-types'

import './ibanRequest.scss'

import ArrowIcon from '@images/icons/arrow-down.svg?react'
import CrossIcon from '@images/icons/cross-icon.svg?react'

const IbanIssuing = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setCurrentStep, currentStep, setTotalSteps } = useContext(HeaderContext)

  const { data: merchant } = useGetMerchantQuery(null)
  const { entityType, capabilities } = merchant || {}
  const { enableIbanIssuingFee } = (capabilities as any) || {}

  const isCompany = entityType === MerchantEntityTypeEnum.COMPANY
  const isIndividual = entityType === MerchantEntityTypeEnum.INDIVIDUAL

  const { state }: any = useLocation()
  const { selectedAccountId } = state || {}

  const history = useHistory()

  const enableHints = useMatchMedia({ breakpoint: 800, prefix: 'max' })
  const paymentOptionsCollapseEnable = useMatchMedia({ breakpoint: 1280, prefix: 'max' })

  const {
    selectedScheme,
    setSelectedScheme,
    selectedCurrency,
    setSelectedCurrency,
    selectedAccount,
    setSelectedAccount,
    availableSchemes,
    ibanSchemeCurrencies,
    ibanSchemeCurrenciesError,
    ibanSchemeCurrenciesFetching,
    fees,
    accounts,
    accountsFetching,
    handleRequest,
    previewError,
    initiateError,
    resetMutations,
    setCountryList,
    countryList,
    formValues
  } = useIbanRequest({
    shouldSkipIssuingFee: isCompany || isIndividual,
    onFee: (payload?: BankAccountIssuingPreviewRequest) => {
      modalHide()
      setFeeDetails(payload)
      setCurrentStep(2)
    },
    onSuccess: () => {
      modalHide()
      resetMutations()
      setCurrentStep(3)
    },
    hasQuestionnaire: isIndividual
  })

  const [collapsePaymentOption, setCollapsePaymentOption] = useState<boolean>(false)
  const [countryListError, setCountryListError] = useState<string>('')
  const [feeDetails, setFeeDetails] = useState<BankAccountIssuingPreviewRequest>(null)

  const { countryOptions, countriesFetching } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)

  const handleContinue = () => {
    if (isIndividual && currentStep == 1) {
      setCurrentStep(2)
    } else {
      if (isIndividual && !isValidArray(countryList)) {
        setCountryListError(tr('VALIDATION.ERRORS.REQUIRED', 'This field should not be empty'))
      }
      if (isIndividual && (!formValues.areValid() || !isValidArray(countryList))) return
      void handleRequest()
    }
  }

  const handleAddAccount = () => {
    modalShow({
      header: tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'Add new account'),
      options: { closeOnClickOutside: false },
      content: (
        <AccountCreateModal
          filteredCurrencies={[selectedCurrency]}
          onSuccess={(currencyCode: string) => {
            resetMutations()
            toast.remove()
            toast.success(
              tr('FRONTEND.ACCOUNTS.CREATE.SUCCESS_MESSAGE', '{{currencyCode}} account successfully added', {
                currencyCode
              })
            )
          }}
        />
      )
    })
  }

  // effects
  useEffect(() => {
    if (isValidArray(availableSchemes)) {
      const firstScheme = availableSchemes[0]
      setSelectedCurrency(ibanSchemeCurrencies[firstScheme][0])
    }
  }, [ibanSchemeCurrenciesFetching])

  useEffect(() => {
    if (selectedScheme && paymentOptionsCollapseEnable) {
      return setCollapsePaymentOption(false)
    }
    if (!selectedScheme && paymentOptionsCollapseEnable) {
      setCollapsePaymentOption(true)
    }
  }, [selectedScheme, paymentOptionsCollapseEnable])

  // preselect first currency
  useEffect(() => {
    if (isValidObject(availableSchemes)) {
      setSelectedScheme(availableSchemes[0] as BankAccountScheme)
    }
  }, [availableSchemes])
  // preselect first account
  useEffect(() => {
    if (selectedAccount) {
    }
  }, [selectedAccount])

  useEffect(() => {
    if (accounts) {
      setSelectedAccount(accounts[0])
    }
  }, [])

  useEffect(() => {
    if ((!selectedAccount || (selectedAccountId && selectedAccount.id !== selectedAccountId)) && !accountsFetching) {
      selectedAccountId
        ? setSelectedAccount(accounts.find(account => account.id === selectedAccountId))
        : setSelectedAccount(accounts[0])
    }
  }, [accountsFetching, selectedAccountId])

  useEffect(() => {
    setCurrentStep(1)
    setTotalSteps([
      {
        step: 1,
        context: tr('PORTAL.IBAN_REQUEST.STEP_ACCOUNT_INFO', 'Account Information'),
        subtitle: ''
      },
      ...(isIndividual
        ? [
            {
              step: 2,
              context: tr('PORTAL.IBAN_REQUEST.STEP_QUESTIONNAIRE', 'Questionnaire'),
              subtitle: ''
            }
          ]
        : []),
      {
        step: isIndividual ? 3 : 2,
        context: tr('PORTAL.IBAN_REQUEST.STEP_REVIEW', 'Review'),
        subtitle: ''
      }
    ])
  }, [merchant])

  return (
    <>
      {currentStep === 1 && (
        <div className='iban-request-container'>
          <div className='iban-request-wrapper'>
            <div className='iban-request-left-container'>
              <div className='iban-request-select-method-wrapper'>
                <div className={cn('iban-request-select-method-header', { collapsed: collapsePaymentOption })}>
                  <span className='title'>
                    {isValidObject(ibanSchemeCurrencies) &&
                    ibanSchemeCurrencies &&
                    Object.keys(ibanSchemeCurrencies).length == 1
                      ? tr('PORTAL.IBAN_REQUEST.PAYMENT_METHODS_LABEL', 'Payment method')
                      : tr('PORTAL.IBAN_REQUEST.PAYMENT_METHODS_SELECT', 'Select payment method')}
                  </span>
                  {paymentOptionsCollapseEnable && (
                    <span className='select-method-arrow' onClick={() => setCollapsePaymentOption(val => !val)}>
                      <ArrowIcon />
                    </span>
                  )}
                </div>
                {isValidObject(ibanSchemeCurrencies) && (
                  <IbanRequestMethods
                    options={Object.keys(ibanSchemeCurrencies)}
                    setSelectedOption={setSelectedScheme}
                    selectedOption={selectedScheme}
                    collapse={collapsePaymentOption}
                    setCollapse={setCollapsePaymentOption}
                    dataTest='iban-request-method'
                  />
                )}
              </div>
            </div>
            <div className='iban-request-right-container'>
              <div className='iban-request-right-top'>
                <span className='iban-request-title'>
                  {isValidObject(ibanSchemeCurrencies) && ibanSchemeCurrencies[selectedScheme]?.length === 1
                    ? tr('PORTAL.IBAN_REQUEST.CURRENCY_LABEL', 'Currency for your IBAN')
                    : tr('PORTAL.IBAN_REQUEST.CURRENCY_SELECT', 'Choose a currency for your IBAN')}
                </span>
                <div className='iban-request-currency-wrapper'>
                  {isValidObject(ibanSchemeCurrencies) &&
                  selectedCurrency &&
                  ibanSchemeCurrencies[selectedScheme]?.length === 1 ? (
                    <div className='iban-request-single-currency-field'>
                      <CoreCurrencyIcon currency={selectedCurrency} width='32px' height='32px' circle />
                      <span className='iban-request-single-currency-text'>{selectedCurrency}</span>
                    </div>
                  ) : (
                    <>
                      {selectedCurrency && (
                        <div className='iban-request-multi-currency-field'>
                          <CoreSelect
                            type='currency'
                            name='attachmentType'
                            required
                            data-test='destination-payment-instrument-id'
                            label={tr('PORTAL.IBAN_REQUEST.ATTACHMENT_TYPE', 'Attachment type')}
                            value={selectedCurrency}
                            options={ibanSchemeCurrencies[selectedScheme]}
                            onChange={(_name: string, value: string) => setSelectedCurrency(value as string)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={cn('iban-request-select-account-wrapper', {
                    spacing: !(isIndividual || isCompany) || !isValidArray(accounts)
                  })}>
                  <div className='iban-request-selected-amount-descriptions-wrapper'>
                    {isValidArray(accounts) ? (
                      <>
                        <span className='iban-request-select-account-title'>
                          {tr(
                            'PORTAL.IBAN_REQUEST.SELECT_ACCOUNT',
                            'Choose a currency account to associate with the IBAN'
                          )}
                        </span>
                      </>
                    ) : (
                      <span className='iban-request-select-account-title'>
                        {tr(
                          'PORTAL.IBAN_REQUEST.NO_AVAILABLE_ACCOUNTS',
                          'No available accounts in the currency you chose.'
                        )}
                      </span>
                    )}
                  </div>
                  {isValidArray(accounts) ? (
                    <CoreSelect
                      type='account'
                      label={tr('PORTAL.IBAN_REQUEST.ACCOUNT', 'Account')}
                      options={accounts}
                      isAccountsLoading={accountsFetching}
                      onChange={(_, v) => {
                        setSelectedAccount(v)
                      }}
                      value={selectedAccount}
                    />
                  ) : (
                    <CoreButton
                      fullWidth
                      size='large'
                      onClick={handleAddAccount}
                      text={tr('PORTAL.IBAN_REQUEST.NEW_ACCOUNT', 'New account')}
                      LeftIcon={<CrossIcon />}
                    />
                  )}
                </div>
              </div>

              {enableIbanIssuingFee && (
                <div className='iban-request-fee-wrapper'>
                  <div>
                    {fees.map((fee: any) => {
                      const { feeType, feeCurrency, value } = fee
                      return (
                        <div className='iban-request-fee-content' key={feeType}>
                          <div className='fee-title'>
                            {feeType === 'IBAN_ISSUING_FEE'
                              ? tr('PORTAL.IBAN_REQUEST.IBAN_ISSUING_FEE', 'Issuing fee')
                              : tr('PORTAL.IBAN_REQUEST.MAINTENANCE_FEE', 'Monthly maintenance fee')}
                            :
                          </div>
                          <div className='fee-amount'>
                            {formatFloat(value, getCurrencyDecimals(feeCurrency))}{' '}
                            <span className='fee-amount-currency'>{feeCurrency}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              <div className='iban-request-right-footer'>
                <ErrorDisplay error={[ibanSchemeCurrenciesError, previewError, initiateError]} />
                <CoreButton
                  variation='primary'
                  size='large'
                  fullWidth
                  onClick={handleContinue}
                  disabled={!selectedCurrency || !selectedAccount}
                  text={tr('PORTAL.IBAN_REQUEST.SUBMIT_BUTTON', 'Submit')}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <>
          {feeDetails ? (
            <IbanRequestFeePayment feeDetails={feeDetails} onSuccess={() => setCurrentStep(3)} />
          ) : (
            <div className='iban-request-questionnaire-container'>
              <div className='iban-request-questionnaire-wrapper'>
                <CoreSelect
                  type='country'
                  name='countryList'
                  value={countryList}
                  options={countryOptions}
                  data-test='file-type'
                  label={
                    enableHints
                      ? null
                      : tr('PORTAL.IBAN_QUESTIONNAIRE.COUNTRY', 'Countries from which you will be funding the IBAN')
                  }
                  onChange={(_name: any, value: any) => {
                    setCountryListError('')
                    setCountryList(typeof value === 'string' ? [...(countryList || []), value] : value)
                  }}
                  loading={countriesFetching}
                  isMultiply
                  error={countryListError}
                  required
                  hint={
                    enableHints
                      ? tr('PORTAL.IBAN_QUESTIONNAIRE.COUNTRY', 'Countries from which you will be funding the IBAN')
                      : null
                  }
                />

                <CoreInput
                  name='yearlyTransactionsAmountInEur'
                  type='number'
                  label={
                    enableHints
                      ? null
                      : tr(
                          'PORTAL.IBAN_QUESTIONNAIRE.AMOUNT',
                          'Average value of your transaction(s) (EUR) in a year(12 months)'
                        )
                  }
                  required
                  {...formValues.registerInput('yearlyTransactionsAmountInEur')}
                  hint={
                    enableHints
                      ? tr(
                          'PORTAL.IBAN_QUESTIONNAIRE.AMOUNT',
                          'Average value of your transaction(s) (EUR) in a year(12 months)'
                        )
                      : null
                  }
                />

                <CoreInput
                  name='totalNumOfBankAccounts'
                  type='number'
                  label={
                    enableHints
                      ? null
                      : tr(
                          'PORTAL.IBAN_QUESTIONNAIRE.BANK_ACCOUNTS',
                          'How many bank accounts are you holding at the moment?'
                        )
                  }
                  required
                  {...formValues.registerInput('totalNumOfBankAccounts')}
                  hint={
                    enableHints
                      ? tr(
                          'PORTAL.IBAN_QUESTIONNAIRE.BANK_ACCOUNTS',
                          'How many bank accounts are you holding at the moment?'
                        )
                      : null
                  }
                />

                <CoreInput
                  name='linkedBankAccountsCount'
                  type='number'
                  label={
                    enableHints
                      ? null
                      : tr(
                          'PORTAL.IBAN_QUESTIONNAIRE.ACCOUNTS_NUMBER',
                          'How many payment methods/bank accounts will be used to transact through this IBAN?'
                        )
                  }
                  required
                  {...formValues.registerInput('linkedBankAccountsCount')}
                  hint={
                    enableHints
                      ? tr(
                          'PORTAL.IBAN_QUESTIONNAIRE.ACCOUNTS_NUMBER',
                          'How many payment methods/bank accounts will be used to transact through this IBAN?'
                        )
                      : null
                  }
                />

                <CoreInput
                  name='occupation'
                  type='text'
                  label={enableHints ? null : tr('PORTAL.IBAN_QUESTIONNAIRE.OCCUPATION', 'Occupation')}
                  required
                  {...formValues.registerInput('occupation')}
                  hint={enableHints ? tr('PORTAL.IBAN_QUESTIONNAIRE.OCCUPATION', 'Occupation') : null}
                />

                <CoreInput
                  name='purposeOfAccount'
                  type='text'
                  label={enableHints ? null : tr('PORTAL.IBAN_QUESTIONNAIRE.PURPOSE', 'Purpose of this account')}
                  required
                  {...formValues.registerInput('purposeOfAccount')}
                  hint={enableHints ? tr('PORTAL.IBAN_QUESTIONNAIRE.PURPOSE', 'Purpose of this account') : null}
                />

                <CoreButton
                  variation='primary'
                  size='large'
                  fullWidth
                  onClick={handleContinue}
                  disabled={!selectedCurrency || !selectedAccount}
                  text={tr('PORTAL.IBAN_QUESTIONNAIRE.SUBMIT_BUTTON', 'Submit')}
                />
              </div>
              <ErrorDisplay error={[ibanSchemeCurrenciesError, previewError, initiateError]} />
            </div>
          )}
        </>
      )}

      {currentStep === 3 && (
        <IbanRequestSuccessPane
          onSuccess={() => history.push(paths.dashboard())}
          selectedScheme={selectedScheme}
          selectedCurrency={selectedCurrency}
          selectedAccount={selectedAccount}
          fees={fees}
        />
      )}
    </>
  )
}

export default IbanIssuing

import cn from 'classnames'
import { globalSettings, useOtpQry } from 'mmfintech-backend-api'
import { formatPhoneNumber, OtpContext, tr, useValidUntilTimer } from 'mmfintech-commons'

import { OtpInput } from './OtpInput'
import { CoreButton } from '../CoreButton'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { ChallengeDetails, LoginStatusEnum } from 'mmfintech-commons-types'

import './otp.scss'

import OtpConfirmationImage from '@images/otp-confirmation.png'
import SmartIdConfirmationImage from '@images/smartId-confirmation.png'

import SmartIdIcon from '@images/icons/smart-id-icon.svg?react'
import SMSIcon from '@images/icons/sms-icon.svg?react'
import QRIcon from '@images/icons/qr-icon.svg?react'
import { useContext, useEffect } from 'react'
import { CoreLoader } from '@components'

export const prepareOtpTitle = (challenge: ChallengeDetails, userStatus: string) => {
  const { twoFactorType, smartId } = challenge || {}

  if (smartId) {
    return tr('FRONTEND.VERIFY_OTP.SMART_ID.TITLE', "Verify it's you")
  }

  if (twoFactorType === 'SMS') {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS confirmation')
  }

  if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
    return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
  }
  return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
}

export const OtpNew = () => {
  const {
    code,
    codeSize,
    error,
    attempts,
    challenge,
    availableTwoFactorTypes,
    handleSubmit,
    handleResend,
    handleCodeChanged,
    handleChangeOtpType,
    handleReissueSmartId,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpError,
    resendTimer,
    handleCancel
  } = useOtpQry({ autoSubmit: true })

  const timer = useValidUntilTimer()
  const { setOtpOnError, setOtpOnSuccess } = useContext(OtpContext)

  const { sentTo, twoFactorType, smartId } = challenge || {}

  useEffect(() => {
    return () => {
      setOtpOnSuccess(null)
      setOtpOnError(null)
    }
  }, [])

  const alternativeOtpMethods = (method: string) => {
    switch (method) {
      case 'SMS':
        return (
          <div className='alternative-method-wrapper'>
            <SMSIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMS.LABEL', 'SMS')}</span>
          </div>
        )
      case 'SMART_ID':
        return (
          <div className='alternative-method-wrapper'>
            <SmartIdIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMART_ID.LABEL', 'Smart ID')}</span>
          </div>
        )
      case 'QR':
        return (
          <div className='alternative-method-wrapper'>
            <QRIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.QR.LABEL', 'QR code')}</span>
          </div>
        )
      case 'EMAIL':
        return (
          <div className='alternative-method-wrapper'>
            <QRIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.EMAIL.LABEL', 'Email')}</span>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='otp-modal-wrapper'>
      <form
        noValidate
        onSubmit={() => {
          if (!verifyOtpFetching) {
            void handleSubmit()
          }
        }}>
        <img src={smartId ? SmartIdConfirmationImage : OtpConfirmationImage} alt='confirmation-icon' />
        <div className='otp-modal-subtitle'>
          {smartId ? (
            tr(
              'FRONTEND.VERIFY_OTP.SMART_ID.TEXT',
              'We’ve sent a notification to your device. Please check and confirm to verify it’s you.'
            )
          ) : (
            <div className='otp-verification-text'>
              {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
              <div className='otp-verification-target'>
                {twoFactorType === 'SMS' ? formatPhoneNumber(sentTo) : sentTo}
              </div>
            </div>
          )}
        </div>

        <ErrorDisplay error={[verifyOtpError, resendOtpError]} />

        {smartId ? (
          <>
            <ErrorDisplay error={[error, resendOtpError]} />
            <span onClick={handleReissueSmartId} className='otp-resend-button'>
              {tr('FRONTEND.VERIFY_OTP.SMART_ID.SEND_AGAIN', 'Send Again')}
            </span>
          </>
        ) : (
          <>
            <OtpInput
              codeLength={codeSize}
              onChange={handleCodeChanged}
              disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
              value={code}
            />

            <div className='countdown'>{timer.formattedTime}</div>
            {attempts < globalSettings.otpMaxAttempts ? (
              <span onClick={handleResend} className={cn('otp-resend-button', { disabled: !resendTimer.expired })}>
                {resendTimer.expired
                  ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                  : resendTimer.remainingTime}
              </span>
            ) : (
              tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
            )}
          </>
        )}

        <div className='otp-modal-methods-title'>
          {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it? Retry via:")}
        </div>

        <div className={cn('otp-modal-methods-actions', { disable: !resendTimer.expired })}>
          {attempts < globalSettings.otpMaxAttempts ? (
            <>
              {availableTwoFactorTypes &&
                availableTwoFactorTypes.map(type => {
                  return (
                    <div
                      className='otp-alternative-method'
                      key={type}
                      onClick={() => {
                        resendTimer.expired && handleChangeOtpType({ type })
                      }}>
                      {alternativeOtpMethods(type)}
                    </div>
                  )
                })}
            </>
          ) : (
            tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
          )}
        </div>

        {/* {!smartId && (
          <CoreButton
            text={tr('FRONTEND.BUTTONS.SUBMIT', 'Submit')}
            fullWidth={true}
            variation='primary'
            disabled={code.length < codeSize || errorKey === TEMPORARY_UNAVAILABLE_OTP}
            isLoading={verifyOtpFetching || resendOtpFetching}
            size='large'
            type='button'
            onClick={() => {
              if (!verifyOtpFetching) {
                void handleSubmit()
              }
            }}
          />
        )} */}

        {verifyOtpFetching ? (
          <CoreLoader />
        ) : (
          <CoreButton
            fullWidth
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={() => handleCancel()}
            size='large'
            isLoading={verifyOtpFetching}
          />
        )}
      </form>
    </div>
  )
}

import { CoreCurrencyIcon } from '@components'

import './accountPreview.scss'

export const AccountPreview = ({ account }) => {
  if (account) {
    const { currencyCode, name } = account

    return (
      <div className='account-preview-container'>
        <CoreCurrencyIcon className='currency-icon' currency={currencyCode} width='26px' height='26px' />

        <div className='account-preview-content'>
          <span className='currency'>{currencyCode}</span> - {name ? <span>{name}</span> : null}
        </div>
      </div>
    )
  }

  return null
}

import cn from 'classnames'
import './CoreCurrencyIcon.scss'
import LoadingIcon from '@images/loader-icon.svg?react'

interface CurrencyIconProps {
  currency: string | undefined
  width?: string
  height?: string
  circle?: boolean
  className?: string
  isLoading?: boolean
}

export const CoreCurrencyIcon = (props: CurrencyIconProps) => {
  const { currency, width = '3rem', height = '3rem', circle = false, className = '', isLoading = false } = props

  return (
    <>
      {isLoading ? (
        <div
          className='currency-icon-loading'
          style={{
            minWidth: width,
            minHeight: height
          }}>
          <LoadingIcon />
        </div>
      ) : (
        <div
          style={{
            minWidth: width,
            minHeight: height
          }}
          className={cn('currency-icon', className, currency?.replace(/\./g, '-'), { circle })}
        />
      )}
    </>
  )
}

export const findCurrencyIconSrc = (currency: string) => {
  switch (currency) {
    case 'XOF':
      return '/src/images/currencies/xaf_xof.png'
    case 'AUDD.ERC20':
      return '/src/images/currencies/audd.png'
    case 'EURK.ERC20':
      return '/src/images/currencies/eurk.png'
    case 'EURT.ERC20':
      return '/src/images/currencies/eurt.png'
    case 'USDC.ERC20':
    case 'USDC.TRC20':
      return '/src/images/currencies/usdc.png'
    case 'USDT.ERC20':
    case 'USDT.TRC20':
      return '/src/images/currencies/usdt.png'
    default:
      return `/src/images/currencies/${currency.toLowerCase()}.png`
  }
}

// import FlagIcon from '../FlagIcon'
import { CoreCurrencyIcon } from '@components'
import type { SelectionType } from './selectionTypes'

export function filterOptions(type: SelectionType, options: any[], filterString: string = '') {
  if (options) {
    switch (type) {
      case 'account':
        return options.filter(data => data.name?.toLowerCase().includes(filterString.toLowerCase().trim()))
      case 'country':
        return options.filter(data => data.label?.toLowerCase().includes(filterString.toLowerCase().trim()))
      case 'default':
        return options.filter(data => data.label?.toLowerCase().includes(filterString.toLowerCase().trim()))
      default:
        return options.filter(data => JSON.stringify(data)?.toLowerCase().includes(filterString.toLowerCase().trim()))
    }
  }

  return []
}

export function SelectedIcon({
  type,
  selectedOption,
  isLoading
}: {
  type: SelectionType
  selectedOption: any
  isLoading?: boolean
}) {
  if (selectedOption) {
    switch (type) {
      case 'account':
        return <CoreCurrencyIcon currency={selectedOption.currencyCode} width='30px' height='30px' circle />
      // case 'country':
      //   return <FlagIcon countryCode={selectedOption.value} width='30px' height='20px' />
    }
  }
  if (!selectedOption && isLoading && type === 'account') {
    return (
      <CoreCurrencyIcon
        isLoading={isLoading}
        currency={selectedOption?.currencyCode}
        width='30px'
        height='30px'
        circle
      />
    )
  }

  return <></>
}

export function selectedLabel(type: SelectionType, selectedOption: any) {
  if (selectedOption) {
    switch (type) {
      case 'account':
        return selectedOption.name
      case 'country':
        return selectedOption.label
      case 'default':
        return selectedOption.label
      case 'currency':
        return selectedOption.label
    }
  }

  return selectedOption.toString()
}

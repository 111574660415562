import { useContext, useState } from 'react'
import toast from 'react-hot-toast'

import { useGetProfileQuery, useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { checkFinalValue, fixPhoneNumber, isEmptyString, OtpContext, tr, translateError } from 'mmfintech-commons'

import { CoreButton, CoreCheckbox, CoreInput, CoreModalDialogFooter } from '@components'

import '../styled/twoFactorModal.scss'

function Change2FA() {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { data: profile } = useGetProfileQuery(null)
  const { email, twoFactor, twoFactorPhone } = profile || {}
  const [new2FA, setNew2FA] = useState(twoFactor)
  const [phone, setPhone] = useState(twoFactorPhone)
  const [phoneError, setPhoneError] = useState(null)

  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()

  const handleSubmit = e => {
    if (e) e.preventDefault()

    const result = checkFinalValue(fixPhoneNumber(phone), {
      validation: 'phone',
      required: isEmptyString(twoFactorPhone)
    })
    if (!result.valid) {
      setPhoneError(translateError(result))
      return
    }

    setOtpOnSuccess(() => response => {
      if (!response?.challenge) {
        toast.success(tr('FRONTEND.SECURITY.TWO_FACTOR.SUCCESS_MESSAGE', '2FA is saved'))
      }
    })

    if (new2FA && new2FA !== twoFactor) {
      if (new2FA === TwoFactorTypeEnum.SMS) {
        change2fa({
          twoFactor: new2FA,
          phone: fixPhoneNumber(phone)
        })
      } else {
        change2fa({
          twoFactor: new2FA
        })
      }
    }
  }

  return (
    <form className='profile-two-factor-ver-container' noValidate onSubmit={handleSubmit} data-test='change-2fa-modal'>
      <div className='profile-two-factor-main-content'>
        <CoreInput
          key='e-mail'
          data-test='2FA-email-button'
          setFocus={value => {
            value && setNew2FA(TwoFactorTypeEnum.EMAIL)
          }}
          type='text'
          label={tr('FRONTEND.SECURITY.TWO_FACTOR.EMAIL', 'E-mail')}
          value={email}
          LeftIcon={
            <CoreCheckbox type='circle' value={TwoFactorTypeEnum.EMAIL} checked={new2FA == TwoFactorTypeEnum.EMAIL} onClick={() => setNew2FA(TwoFactorTypeEnum.EMAIL)}/>
          }
        />

        <CoreInput
          key='phone'
          data-test='2FA-SMS-button'
          setFocus={value => {
            value && setNew2FA(TwoFactorTypeEnum.SMS)
          }}
          type='phone'
          label={tr('FRONTEND.SECURITY.TWO_FACTOR.SMS', 'SMS')}
          value={phone}
          error={phoneError}
          onChange={(_name, value) => setPhone(value.toString())}
          LeftIcon={
            <CoreCheckbox type='circle' value={TwoFactorTypeEnum.SMS} checked={new2FA == TwoFactorTypeEnum.SMS} onClick={() => setNew2FA(TwoFactorTypeEnum.SMS)}/>
          }
        />
      </div>

      <ErrorDisplay error={change2faError} />
      <CoreModalDialogFooter>
        <CoreButton
          data-test='2FA-save-button'
          text={tr('FRONTEND.ACCOUNTS.DETAILS.BUTTON.SAVE', 'Save')}
          fullWidth
          size='large'
          disabled={new2FA && new2FA === twoFactor}
        />
      </CoreModalDialogFooter>
    </form>
  )
}

export default Change2FA

import { memo, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import fileDownload from 'js-file-download'
import isEqual from 'lodash.isequal'
import toast from 'react-hot-toast'

import {
  endpoints,
  paths,
  useFetchInvoiceByIdQuery,
  useFileDownloader,
  useGetLogoQuery,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { tr, formatDate, copyTextToClipboard, isValidArray, formatFloat, getCurrencyDecimals } from 'mmfintech-commons'
import { DataTable, ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreLoader, CoreButton, CoreProfileAvatar } from '@components'
import { HeaderContext } from '../../../context/HeaderContext'
import { SendInvoiceByEmail } from './SendInvoiceByEmail'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'

import NewInvoiceIcon from '@images/icons/create-invoice-icon.svg?react'
import CopyLinkIcon from '@images/icons/get-link-invoices.svg?react'
import DownloadIcon from '@images/icons/download-invoices.svg?react'
import Success from '@images/icons/success-check.svg?react'

import './invoiceSend.scss'

const InvoiceSend = (props: { invoiceIdPreview?: number; invoicePreview?: any; match?: any }) => {
  const { invoiceIdPreview, invoicePreview, match } = props
  const invoiceId = match?.params?.['invoiceId']
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'max' })
  const {
    data: invoice,
    error: invoiceError,
    isFetching: invoiceFetching
  } = useFetchInvoiceByIdQuery({ invoiceId: invoiceId || invoiceIdPreview }, { skip: invoicePreview })
  const [disableDownload, setDisableDownload] = useState(false)
  const downloader = useFileDownloader()
  const history = useHistory()
  const { setCurrentStep } = useContext(HeaderContext)
  const location: any = useLocation()
  const { data: merchantLogo } = useGetLogoQuery(undefined, { skip: invoicePreview })
  const { data: merchant } = useGetMerchantQuery(undefined, { skip: invoicePreview })

  useEffect(() => {
    setCurrentStep(2)
  }, [])

  const handleDownloadClick = () => {
    if (!disableDownload) {
      setDisableDownload(true)
      setTimeout(() => setDisableDownload(false), 5000)

      void downloader.download({
        url: endpoints.invoices.exportInvoiceAsPdf(invoiceId),
        method: 'GET',
        onSuccess: (data: any, filename: string) => {
          fileDownload(data, filename || `invoice-${invoiceId}.pdf`)
        }
      })
    }
  }

  const {
    invoiceNumber,
    ownInvoiceNumber,
    payerName,
    dueDate,
    total,
    currency,
    date,
    notes,
    invoiceUrl,
    subtotal,
    vat,
    lines,
    payerEmail
  } = invoice || invoicePreview || {}

  const handleGetLinkClick = () => {
    copyTextToClipboard(invoiceUrl, () => {
      toast.remove()
      toast.success(tr('FRONTEND.INVOICES.LINK.COPY_SUCCESS', 'Link copied to clipboard.').toString())
    })
  }

  return invoiceFetching ? (
    <CoreLoader />
  ) : (
    <div className={`send-invoice-wrapper ${invoiceIdPreview ? 'preview-modal' : ''}`}>
      <div className='send-invoice-container'>
        {!invoiceIdPreview && (
          <div className='send-invoice-header'>
            <div className='send-invoice-header-left'>
              {location?.state && location.state == 'CREATED' && (
                <>
                  <div className='success-icon'>
                    <Success style={{ width: '42px', height: '42px' }} />
                  </div>
                  <span className='title'>
                    {tr('FRONTEND.INVOICES.SEND.LABEL_SUCCESS', 'Your invoice has been successfully created!')}
                  </span>
                </>
              )}
              {location?.state && location.state == 'EDITED' && (
                <>
                  <div className='success-icon'>
                    <Success style={{ width: '42px', height: '42px' }} />
                  </div>
                  <span className='title'>
                    {tr('FRONTEND.INVOICES.SEND.EDIT_SUCCESS', 'Your invoice has been successfully edited!')}
                  </span>
                </>
              )}
              {!location.state && <CoreProfileAvatar image={merchantLogo} name={merchant?.name} />}
            </div>
            <CoreButton
              data-test='button-download'
              onClick={handleDownloadClick}
              variation='tertiary'
              text={'Download as PDF'}
              size='normal'
              LeftIcon={<DownloadIcon />}
            />
          </div>
        )}

        <div className='send-invoice-content'>
          <span className='summary-title'>{tr('FRONTEND.INVOICES.SEND.LABEL_SUMMARY', 'Summary')}</span>
          <div className='invoice-info'>
            <InformationRow label={tr('FRONTEND.INVOICES.SEND.LABEL_TO', 'To')} value={payerName} dataTest='payer' />
            <InformationRow label={tr('FRONTEND.INVOICES.SEND.INVOICE_NUMBER', 'Invoice No')} value={invoiceNumber} />
            <InformationRow
              label={tr('FRONTEND.INVOICES.SEND.ORIGINAL_INVOICE_NO', 'Original Invoice No')}
              value={ownInvoiceNumber || tr('FRONTEND.INVOICES.LIST.N_A', 'N/A')}
            />
            <InformationRow label={'Created Date'} value={formatDate(date)} />
            <InformationRow
              label={tr('FRONTEND.INVOICES.SEND.LABEL_DUE_DATE', 'Due Date')}
              dataTest='due_date'
              value={formatDate(dueDate)}
            />
            <InformationRow label={'Note'} value={notes} />
          </div>
        </div>
        {isValidArray(lines) && (
          <div className='table-container'>
            <DataTable.Table>
              <DataTable.Head>
                <DataTable.Row>
                  {!isMobile && (
                    <DataTable.HeadCell className='thin no-border  table-header-cell'>
                      {tr('FRONTEND.INVOICES.SEND.TABLE.ID', 'ID')}
                    </DataTable.HeadCell>
                  )}
                  <DataTable.HeadCell className='thin no-border  table-header-cell'>
                    {tr('FRONTEND.INVOICES.SEND.TABLE.ITEM', 'Item')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell className='thin no-border  table-header-cell'>
                    {tr('FRONTEND.INVOICES.SEND.TABLE.DESCRIPTION', 'Description')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell className='thin no-border  table-header-cell'>
                    {tr('FRONTEND.INVOICES.SEND.TABLE.QTY', 'Qty')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell className='thin no-border  table-header-cell'>
                    {tr('FRONTEND.INVOICES.SEND.TABLE.COST', 'Cost')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell className='thin no-border  table-header-cell'>
                    {tr('FRONTEND.INVOICES.SEND.TABLE.VAT', 'Vat (%)')}
                  </DataTable.HeadCell>
                  {!isMobile && (
                    <DataTable.HeadCell className='thin no-border  table-header-cell' style={{ textAlign: 'right' }}>
                      {tr('FRONTEND.INVOICES.SEND.TABLE.AMOUNT', 'Amount')}
                    </DataTable.HeadCell>
                  )}
                </DataTable.Row>
              </DataTable.Head>

              <DataTable.Body>
                {lines.map((data: any, index: number) => {
                  const { id, initPrice, lineTotal, productService, productServiceDescription, quantity, vat } = data
                  return (
                    <DataTable.Row key={`data-${index}`} data-test='table-row'>
                      {!isMobile && (
                        <DataTable.Cell caption={tr('FRONTEND.INVOICES.SEND.TABLE.ID', 'ID')}>{id}</DataTable.Cell>
                      )}
                      <DataTable.Cell caption={tr('FRONTEND.INVOICES.SEND.TABLE.ITEM', 'ITEM')}>
                        {productService}
                      </DataTable.Cell>
                      <DataTable.Cell caption={tr('FRONTEND.INVOICES.SEND.TABLE.DESCRIPTION', 'Description')}>
                        {productServiceDescription}
                      </DataTable.Cell>
                      <DataTable.Cell caption={tr('FRONTEND.INVOICES.SEND.TABLE.QTY', 'Qty')}>
                        {quantity}
                      </DataTable.Cell>
                      <DataTable.Cell caption={tr('FRONTEND.INVOICES.SEND.TABLE.COST', 'Cost')}>
                        {formatFloat(initPrice, getCurrencyDecimals(currency))}{' '}
                        <span className='amount-currency'>{currency}</span>
                      </DataTable.Cell>
                      <DataTable.Cell caption={tr('FRONTEND.INVOICES.SEND.TABLE.VAT', 'Vat')}>
                        {formatFloat(vat, getCurrencyDecimals(currency))}{' '}
                        <span className='amount-currency'>{currency}</span>
                      </DataTable.Cell>
                      {!isMobile && (
                        <DataTable.Cell
                          caption={tr('FRONTEND.INVOICES.SEND.TABLE.AMOUNT', 'Amount')}
                          style={{ textAlign: 'right' }}>
                          {formatFloat(lineTotal, getCurrencyDecimals(currency))}{' '}
                          <span className='amount-currency'>{currency}</span>
                        </DataTable.Cell>
                      )}
                    </DataTable.Row>
                  )
                })}
              </DataTable.Body>
            </DataTable.Table>
          </div>
        )}
        <div className='invoice-numbers'>
          <div className='invoice-numbers-row'>
            <span className='label'>{tr('FRONTEND.INVOICES.EDIT.SUBTOTAL', 'Subtotal')}: </span>
            <span className='value'>
              {formatFloat(subtotal, getCurrencyDecimals(currency))} <span className='value-currency'>{currency}</span>
            </span>
          </div>
          <div className='invoice-numbers-row'>
            <span className='label'>{tr('FRONTEND.INVOICES.EDIT.VAT', 'VAT')}:</span>
            <span className='value'>
              {formatFloat(vat, getCurrencyDecimals(currency))} <span className='value-currency'>{currency}</span>
            </span>
          </div>
          <div className='invoice-numbers-row total'>
            <span className='label'>{tr('FRONTEND.INVOICES.EDIT.TOTAL', 'Total')}:</span>
            <span className='value'>
              {formatFloat(total, getCurrencyDecimals(currency))} <span className='value-currency'>{currency}</span>
            </span>
          </div>
        </div>
        <ErrorDisplay error={[invoiceError]} />

        {!invoiceIdPreview && (
          <div className='send-invoice-actions'>
            <CoreButton
              variation='elevated'
              onClick={handleGetLinkClick}
              text={tr('FRONTEND.INVOICES.SEND.BUTTON_LINK', 'Get Link')}
              size='large'
              data-test='button-get-link'
              LeftIcon={<CopyLinkIcon />}
            />
            <SendInvoiceByEmail invoiceId={invoiceId} email={payerEmail} />
            <CoreButton
              LeftIcon={<NewInvoiceIcon />}
              text={tr('FRONTEND.INVOICES.LIST.BUTTON_CREATE', 'New Invoice')}
              onClick={() => {
                history.push(paths.invoices.create())
              }}
              size='large'
              data-test='new-invoice-button'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(InvoiceSend, (prevProps, nextProps) => isEqual(prevProps, nextProps))

const InformationRow = ({ label, value, dataTest }: { label: string; value: string; dataTest?: string }) => {
  if (!value) return null

  return (
    <div className={`invoice-info-row `} data-test={dataTest}>
      <span className='label'>{label}</span>
      <span className='value'>{value}</span>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import cn from 'classnames'

import {
  selectCurrentUserId,
  useAppSelector,
  useCreateCounterpartyMutation,
  useCreateExpenseMutation,
  useCreateReportMutation,
  useCurrencies,
  useEditExpenseMutation,
  useGetReportQuery,
  useLazyGetCounterpartiesQuery,
  useLazyGetReportListQuery
} from 'mmfintech-backend-api'
import { fixDateOnly, isSafeString, isValidArray, tr, useFormValues } from 'mmfintech-commons'
import { CounterpartyStatusEnum, Expense, ReportStateEnum } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton, CoreCheckbox, CoreInput, CoreModalDialogFooter, CoreSelect } from '@components'
import { ExpenseDragAndDrop } from '@views/reports/components/ExpenseDragAndDrop'
import { PreviewFile } from '@views/reports/components'

import UploadExpenseIcon from '@images/expenses/upload-expense-modal-icon.svg?react'
import CalendarIcon from '@images/icons/filter-calendar.svg?react'
import AddIcon from '@images/icons/new-icon.svg?react'

import './addExpenseModal.scss'

interface IAddExpenseModal {
  expense?: Expense
  rawFileInfo?: File
  onClose?: () => void
  reportId?: string
  reload?: () => void
}

export const AddExpenseModal: React.FC<IAddExpenseModal> = ({ expense, rawFileInfo, onClose, reportId, reload }) => {
  const params = useParams<{ reportId: string }>()
  const [selectedReportId, setSelectedReportId] = useState()
  const { data: report } = useGetReportQuery({ id: Number(params.reportId || reportId || selectedReportId) })
  const userId = useAppSelector(selectCurrentUserId)

  const { currencies } = useCurrencies()

  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_CURRENCIES', 'All') },
    ...(isValidArray(currencies) ? currencies.map(c => ({ value: c.currencyCode, label: c.currencyCode })) : [])
  ]

  const [getReports, { reports, isReportsLoading, reportsFetching }] = useLazyGetReportListQuery({
    selectFromResult: ({ data, isFetching, isLoading }) => ({
      reports: isValidArray(data?.content)
        ? data.content.map((item: any) => ({ label: item.name, value: item.id }))
        : [],
      isReportsLoading: isLoading,
      reportsFetching: isFetching
    })
  })
  const [getCounterparties, { counterparties, isCounterpartiesLoading, counterpartiesFetching }] =
    useLazyGetCounterpartiesQuery({
      refetchOnReconnect: true,
      refetchOnFocus: true,
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        counterparties: isValidArray(data?.content)
          ? data.content.map((item: any) => ({ label: item.name, value: item.id }))
          : [],
        isCounterpartiesLoading: isLoading,
        counterpartiesFetching: isFetching
      })
    })
  const [createExpense, { error: createError, reset: resetCreate, isLoading: createExpenseLoading }] =
    useCreateExpenseMutation()
  const [editExpense, { error: editError, reset: resetEdit, isLoading: editExpenseLoading }] = useEditExpenseMutation()
  const [createReportRequest, { isLoading: isCreateReportLoading }] = useCreateReportMutation()
  const [createCounterpartyRequest, { isLoading: isCreateCounterpartyLoading }] = useCreateCounterpartyMutation()
  const [fileObjectUrl, setFileObjectUrl] = useState<Expense['displayFile'] | null>(expense?.displayFile)

  const formValues = useFormValues({
    id: { value: expense?.id },
    file: { required: true, value: rawFileInfo || expense?.displayFile },
    reportId: { required: true, value: report ? { label: report?.name, value: report?.id } : null },
    currency: { required: true, value: expense?.currency },
    amount: { required: true, value: expense?.amount },
    dateOfPayment: { required: true, value: expense?.dateOfPayment },
    reimbursement: { required: true, value: !!expense?.reimbursement },
    counterpartyId: {
      required: true,
      validation: 'safe-string',
      value: expense?.counterpartyId
        ? {
            label: expense?.counterpartyName,
            value: expense?.counterpartyId
          }
        : null
    },
    invoiceNumber: { validation: 'safe-string', maxLength: 30, value: expense?.invoiceNumber },
    reference: { validation: 'safe-string', maxLength: 100, value: expense?.reference },
    description: { validation: 'safe-string', maxLength: 100, value: expense?.description },
    dueDate: { value: expense?.dueDate }
  })

  useEffect(() => {
    if (!params.reportId && !reportId && formValues.getValue('reportId')) {
      setSelectedReportId(formValues.getValue('reportId').value)
    }
  }, [formValues.getValue('reportId')])

  useEffect(() => {
    const getOptions = async () => {
      try {
        const payload = { size: 1000 }
        await getReports({
          ...payload,
          deleted: false,
          userId: userId,
          states: [ReportStateEnum.NEW, ReportStateEnum.EDIT]
        }).unwrap()

        await getCounterparties({
          ...payload,
          type: 'MERCHANT',
          includeExpenseMetrics: false,
          state: CounterpartyStatusEnum.ACTIVE,
          isOwner: false
        }).unwrap()
      } catch (err) {
        toast.error(err.error)
      }
    }

    getOptions()
  }, [])

  const handleSaveClick = async (isNew?: boolean) => {
    try {
      if (formValues.areValid()) {
        const preparedValues = formValues.prepare()
        preparedValues.reportId = preparedValues.reportId?.value || preparedValues.reportId
        preparedValues.counterpartyId = preparedValues.counterpartyId?.value || preparedValues.counterpartyId
        preparedValues.dateOfPayment = fixDateOnly(preparedValues.dateOfPayment)
        preparedValues.dueDate = fixDateOnly(preparedValues?.dueDate)
        preparedValues.invoiceNumber = formValues.getValue('invoiceNumber')
        preparedValues.reference = formValues.getValue('reference')
        preparedValues.description = formValues.getValue('description')
        preparedValues.dueDate = formValues.getValue('dueDate')
        const res = !preparedValues.id
          ? await createExpense({ data: preparedValues as any, reportId: preparedValues.reportId }).unwrap()
          : await editExpense({ data: preparedValues as any, reportId: preparedValues.reportId }).unwrap()

        typeof reload === 'function' && reload()

        if (!isNew && res) {
          preparedValues.id
            ? toast.success(tr('FRONTEND.EXPENSE_MODAL.EDITED', 'Expense successfully edited'))
            : toast.success(tr('FRONTEND.EXPENSE_MODAL.CREATED', 'Expense successfully created'))
          typeof onClose === 'function' && !isNew && onClose()
        }

        return true
      }
    } catch (error) {
      formValues.handleErrors(error)

      return false
    }
  }

  const handleNewClick = async () => {
    const isSuccess = await handleSaveClick(true)

    if (isSuccess) {
      formValues.cleanup(['reportId', 'reimbursement'], true)
      handleRemoveFile()
      toast.success(tr('FRONTEND.EXPENSE_MODAL.CREATED', 'Expense successfully created'))
    }
  }

  const handleSelectFile = (file: File) => {
    setFileObjectUrl({ fileUrl: URL.createObjectURL(file), type: file.type })
    formValues.setValue('file', file)
  }

  const handleRemoveFile = () => {
    setFileObjectUrl(null)
    formValues.setValue('file', null)
  }

  const handleCreateNewCounterpartyAndReport = async (name: string, isReport?: boolean) => {
    if (!isSafeString(name)) {
      toast.error(
        'Invalid input. Field must contain only alphanumeric characters, whitespace and special characters apart from <>`={}|'
      )
      return
    }

    try {
      const res: any = isReport
        ? await createReportRequest({ name }).unwrap()
        : await createCounterpartyRequest({ name }).unwrap()
      isReport ? toast.success(`Report created successfully`) : toast.success(`Counterparty created successfully`)

      if (isReport) {
        formValues.setValue('reportId', res?.id)
      } else {
        formValues.setValue('counterpartyId', res?.id)
      }

      return { label: res?.name, value: res?.id }
    } catch (err) {
      formValues.handleErrors(err)
      toast.error(err?.cause?.[0]?.cause || err.error)
    }
  }

  useEffect(() => {
    return () => {
      resetCreate()
      resetEdit()
    }
  }, [])

  return (
    <div className='expense-modal-container'>
      <div className='expense-modal-body'>
        <div className='expense-modal-left-section'>
          <CoreSelect
            type='default'
            loading={isReportsLoading || reportsFetching}
            disabled={isCreateReportLoading || !!report}
            options={reports}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.REPORT', 'Report')}
            value={formValues.getValue('reportId')}
            onChange={(_, value) => formValues.setValue('reportId', value)}
            CreateComponent={({ filterString }: { filterString: string }) => {
              if (!filterString) return null
              const isSameReportName = reports.find(({ label }) => label.toLowerCase() === filterString.toLowerCase())

              if (isSameReportName) return null

              return (
                <CreateButton
                  onClick={() => handleCreateNewCounterpartyAndReport(filterString, true)}
                  filterString={filterString}
                  loading={isCreateReportLoading}
                />
              )
            }}
          />
          <CoreSelect
            type='default'
            loading={isCounterpartiesLoading || counterpartiesFetching}
            disabled={isCreateCounterpartyLoading}
            options={counterparties}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.COUNTERPARTY', 'Counterparty')}
            value={formValues.getValue('counterpartyId')}
            onChange={(_, value) => formValues.setValue('counterpartyId', value)}
            CreateComponent={({ filterString }: { filterString: string }) => {
              if (!filterString) return null
              const isSameConterpartyName = counterparties.find(
                ({ label }) => label.toLowerCase() === filterString.toLowerCase()
              )
              if (isSameConterpartyName) return null

              return (
                <CreateButton
                  onClick={() => handleCreateNewCounterpartyAndReport(filterString)}
                  filterString={filterString}
                  loading={isCreateCounterpartyLoading}
                />
              )
            }}
          />

          <div className='group-inputs'>
            <CoreSelect
              type='default'
              name='currency'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CURRENCY', 'Currency')}
              {...formValues.registerInput('currency')}
              value={formValues.getValue('currency')}
              // onChange={(_, value) => formValues.setValue('currency', value?.value)}
              options={getCurrencyOptions()}
            />

            <CoreInput
              type='number'
              {...formValues.registerInput('amount')}
              placeholder='0.00'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.AMOUNT', 'Amount')}
            />
          </div>

          <CoreCheckbox
            type='check'
            onClick={() => {
              formValues.setValue('reimbursement', !formValues.getValue('reimbursement'))
            }}
            checked={formValues.getValue('reimbursement')}
            value={'reimbursement'}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.REIMBURSEMENT', 'Reimbursement')}
            className='reimbursement-checkbox'
          />

          <div className='date-group-inputs'>
            <CoreInput
              type='date'
              name='dateOfPayment'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DATE', 'Date')}
              {...formValues.registerInput('dateOfPayment')}
              placeholder='Select'
              LeftIcon={<CalendarIcon />}
              rangeDates
              endDate={formValues?.getValue('dueDate')}
              dataPickerForwardYears={5}
              selectsStart
              maxDate={formValues.getValue('dueDate')}
            />
            <CoreInput
              type='date'
              name='dueDate'
              {...formValues.registerInput('dueDate')}
              placeholder='Select'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DUE_DATE', 'Due Date')}
              minDate={formValues.getValue('dateOfPayment')}
              LeftIcon={<CalendarIcon />}
              rangeDates
              startDate={formValues?.getValue('dateOfPayment')}
              dataPickerForwardYears={5}
              selectsEnd
            />
          </div>

          <CoreInput
            type='text'
            {...formValues.registerInput('invoiceNumber')}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.INVOICE_NUMBER', 'Invoice Number')}
          />
          <CoreInput
            type='text'
            {...formValues.registerInput('description')}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DESCRIPTION', 'Description')}
          />
        </div>

        <div className='expense-modal-right-section'>
          <ExpenseDragAndDrop
            file={fileObjectUrl}
            Component={ExpenseModalUploadContainer}
            onSelected={handleSelectFile}
            onRemoveFile={handleRemoveFile}
            error={formValues.getError('file')}
          />
        </div>
      </div>
      <ErrorDisplay error={[createError, editError]} />

      <CoreModalDialogFooter>
        <div className='expense-modal-footer'>
          <CoreButton
            isLoading={createExpenseLoading || editExpenseLoading}
            text={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.SAVE_CLOSE', 'Save & Close')}
            data-test='save-edit-expense'
            onClick={() => {
              handleSaveClick()
            }}
            size='large'
            fullWidth
          />
          {!expense?.id ? (
            <CoreButton
              isLoading={createExpenseLoading || editExpenseLoading}
              text={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.SAVE_NEW', 'Save & New')}
              data-test='save-edit-new-expense'
              onClick={handleNewClick}
              size='large'
              variation='tertiary'
            />
          ) : null}
        </div>
      </CoreModalDialogFooter>
    </div>
  )
}

const ExpenseModalUploadContainer = ({ onRemoveAll, onFileUpload, file, removeFile, error }) => {
  return file ? (
    <PreviewFile removeFile={removeFile} file={file} />
  ) : (
    <div className={`document-container ${cn({ error: !!error })}`}>
      <div
        className={`drag-and-drop-container-in-modal`}
        onClick={() => {
          onRemoveAll()
          onFileUpload()
        }}>
        <>
          <div className='drag-and-drop-image-container'>
            <UploadExpenseIcon />
          </div>
          <div>
            {!window.matchMedia('(pointer: coarse)').matches
              ? tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DRAG_DROP', 'Drag & Drop')
              : tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CLICK_TO_UPLOAD', 'Click to Upload')}
            {!window.matchMedia('(pointer: coarse)').matches ? (
              <div className='drop-receipt-text'>
                {tr('FRONTEND.EXPENSES.EXPENSE_MODAL.OR_CLICK', 'or click here to upload')}
              </div>
            ) : null}
            <ErrorDisplay error={error} hideIcon />
          </div>
        </>
      </div>
    </div>
  )
}

const CreateButton = ({ onClick, filterString, loading }) => {
  return (
    <CoreButton
      isLoading={loading}
      className='create-button'
      onClick={onClick}
      text={
        <div className='create-content'>
          <div>
            <AddIcon /> {filterString}
          </div>
          <span className='create-label'>{tr('FRONTEND.COUNTERPARTY.ACTION.CREATE', 'Create')}</span>
        </div>
      }
      size='normal'
      fullWidth
    />
  )
}

import { useGetMerchantQuery, useGetProfileQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'

export const setCurrentStatus = (key: string) => {
  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus, contactPhone, accountType } = merchant || {}
  const { data: profile } = useGetProfileQuery(null)
  const { twoFactorPhone, email } = profile || {}
  switch (key) {
    case 'accountVerification':
      return accountType === MerchantAccountTypeEnum.PROSPECT
        ? tr(`METADATA.SUMSUB_STATUS.${onboardingStatus}`, onboardingStatus.toLowerCase().split('_').join('-'))
        : tr('METADATA.SUMSUB_STATUS.APPROVED', 'Approved')
    case 'email':
      return email
    case 'twoFactorAuth':
      return profile?.twoFactor?.toLowerCase() || ''
    case 'phoneNumber':
      return twoFactorPhone || contactPhone
    case 'setPhoneNumber':
      return null
    default:
      break
  }
}

import { FC, useState } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import './onboardingBanner.scss'

import { useMatchMedia, useOnboarding, useOnboardingStep } from '@hooks'
import { IsCollapsedMenu } from '@utils'
import cn from 'classnames'
import { breakpoints } from '@constants'

import RedOnboardingIcon from '@images/icons/account_verification_icon.svg?react'

export const OnboardingBanner: FC = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingStatus } = merchant || {}

  const { currentOnboardingStep } = useOnboardingStep()
  const formatStatus = (onboardingStatus: string): string => {
    if (!onboardingStatus) return ''
    return onboardingStatus.charAt(0).toUpperCase() + onboardingStatus.replace(/_/g, ' ').toLocaleLowerCase().slice(1)
  }
  const { startOrContinueOnboarding, payAccountSetupFee } = useOnboarding()
  const [collapseMenu, setCollapsedMenu] = useState<boolean>(false)
  const isMobile = useMatchMedia({ breakpoint: breakpoints.xl, prefix: 'max' })

  if (accountType === MerchantAccountTypeEnum.PROSPECT) {
    if (
      onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
      onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
      onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
    ) {
      return (
        <div
          className={cn('onboarding-banner-wrapper', { 'collapse-menu': collapseMenu })}
          onClick={() => startOrContinueOnboarding()}>
          <IsCollapsedMenu setCollapsedMenu={setCollapsedMenu} />
          <div className='banner-content-left'>
            <div className='icon'>
              <RedOnboardingIcon />
            </div>
            {(!collapseMenu || isMobile) && (
              <>
                <div className='banner-text'>
                  <span>
                    {`${tr(
                      'FRONTEND.ONBOARDING.VERIFY_YOUR_ACCOUNT',
                      'Verify your account'
                    )} ${currentOnboardingStep}/3`}
                  </span>
                  <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
                </div>
              </>
            )}
          </div>
          {(!collapseMenu || isMobile) && <div className='red-dot' />}
        </div>
      )
    }
    if (
      onboardingStatus === OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE ||
      onboardingStatus === OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS ||
      onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
      onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW
    ) {
      return (
        <div
          className={cn('onboarding-banner-wrapper waiting-state', { 'collapse-menu': collapseMenu })}
          onClick={() => startOrContinueOnboarding()}>
          <IsCollapsedMenu setCollapsedMenu={setCollapsedMenu} />
          <div className='banner-content-left'>
            <div className='icon'>
              <RedOnboardingIcon />
            </div>
            {(!collapseMenu || isMobile) && (
              <>
                <div
                  className={cn('banner-text', {
                    under_review: [
                      OnboardingStatusEnum.WAITING_OPS_APPROVAL,
                      OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL
                    ].includes(onboardingStatus)
                  })}>
                  <span>
                    {`${tr(
                      'FRONTEND.ONBOARDING.VERIFY_YOUR_ACCOUNT',
                      'Verify your account'
                    )} ${currentOnboardingStep}/3`}
                  </span>
                  <div className='banner-subtext'>
                    {[
                      OnboardingStatusEnum.WAITING_OPS_APPROVAL,
                      OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL
                    ].includes(onboardingStatus)
                      ? tr('FRONTEND.ONBOARDING.UNDER_REVIEW', 'Under review.')
                      : formatStatus(onboardingStatus)}
                  </div>
                </div>
              </>
            )}
          </div>
          {(!collapseMenu || isMobile) && <div className='waiting-dot' />}
        </div>
      )
    }
    if (onboardingStatus === OnboardingStatusEnum.APPROVED) {
      return (
        <div
          className={cn('onboarding-banner-wrapper approved', { 'collapse-menu': collapseMenu })}
          onClick={() => payAccountSetupFee()}>
          <IsCollapsedMenu setCollapsedMenu={setCollapsedMenu} />
          <div className='banner-content-left'>
            <div className='icon'>
              <RedOnboardingIcon />
            </div>
            {(!collapseMenu || isMobile) && (
              <>
                <div className='banner-text'>
                  <span>
                    {`${tr(
                      'FRONTEND.ONBOARDING.VERIFY_YOUR_ACCOUNT',
                      'Verify your account'
                    )} ${currentOnboardingStep}/3`}
                  </span>
                  <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
                </div>
              </>
            )}
          </div>
          {(!collapseMenu || isMobile) && <div className='approve-dot' />}
        </div>
      )
    }
    // if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
    //   // TODO: clear banner and implement one time modal for finally rejected
    //   return (
    //     <div
    //       className={cn('onboarding-banner-wrapper', { 'collapse-menu': collapseMenu })}
    //       onClick={() => startOrContinueOnboarding()}>
    //       <IsCollapsedMenu setCollapsedMenu={setCollapsedMenu} />
    //       <div className='banner-content-left'>
    //         <div className='icon'>
    //           <RedOnboardingIcon />
    //         </div>
    //         {(!collapseMenu || isMobile) && (
    //           <>
    //             <div className='banner-text'>
    //               <span>
    //                 {`${tr(
    //                   'FRONTEND.ONBOARDING.VERIFY_YOUR_ACCOUNT',
    //                   'Verify your account'
    //                 )} ${currentOnboardingStep}/3`}
    //               </span>
    //               <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
    //             </div>
    //           </>
    //         )}
    //       </div>
    //       {(!collapseMenu || isMobile) && <div className='red-dot' />}
    //     </div>
    //   )
    // }
  }

  return null
}
